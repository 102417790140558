import React from 'react';
import {
  HomePageStarredEntities,
  HomePageRandomJoke,
  WelcomeTitle,
  HomePageTopVisited,
  HomePageRecentlyVisited,
  HeaderWorldClock,
  ClockConfig,
  VisitListener,
} from '@backstage/plugin-home';
import { Content, Page, Header } from '@backstage/core-components';
import { Grid, makeStyles, Typography, Grow } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { DevFriendsDayCard } from '@piatkiewicz/backstage-dev-friends-days';
import { XkcdComicCard } from 'backstage-plugin-xkcd';
import { ShouldIDeployCard } from 'backstage-plugin-should-i-deploy';
import { AnnouncementsCard } from '@backstage-community/plugin-announcements';
import HomeIcon from '@material-ui/icons/Home';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import StarIcon from '@material-ui/icons/Star';
import BuildIcon from '@material-ui/icons/Build';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import SearchIcon from '@material-ui/icons/Search';
import DashboardIcon from '@material-ui/icons/Dashboard';
import KnaufInfoSection from './KnaufInfoSection';
import UsefulLinksCard from './UsefulLinksCard';
import ToolkitCard from './ToolkitCard';
import ChangelogPopup from './ChangelogPopup';
import SupportCard from './SupportCard';
import QuickAccessCards from './QuickAccessCards';
import UserOwnedResources from './UserOwnedResources';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  searchBarInput: {
    maxWidth: '60vw',
    margin: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '50px',
    boxShadow: theme.shadows[1],
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
  sectionTitle: {
    fontSize: '30px',
    paddingTop: '20px',
    paddingBottom: '10px',
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
}));

const useTitleStyles = makeStyles(theme => ({
  title: {
    fontSize: '50px',
    paddingTop: '30px',
    paddingBottom: '20px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
  },
  subtitle: {
    fontSize: '21px',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
}));

const clockConfigs: ClockConfig[] = [
  { label: 'CET', timeZone: 'Europe/Berlin' },
  { label: 'UTC', timeZone: 'UTC' },
  { label: 'STO', timeZone: 'Europe/Stockholm' },
  { label: 'NYC', timeZone: 'America/New_York' },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

export const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const title = useTitleStyles(theme);
  const config = useApi(configApiRef);

  return (
    <SearchContextProvider>
      <VisitListener>
        <Page themeId="home">
          <Header
            title={<WelcomeTitle />}
            pageTitleOverride="Home"
            subtitle="Welcome to the Knauf Digital Internal Developer Platform"
            tooltip="This is the home page"
          >
            <HeaderWorldClock clockConfigs={clockConfigs} customTimeFormat={timeFormat} />
          </Header>
          <Content>
            <Grid container justifyContent="center" spacing={6}>
              <Grid item xs={12}>
                <Typography className={title.title}>
                  {config.getString('app.title')}
                </Typography>
                <Typography className={title.subtitle}>
                  Empowering developers to innovate and deploy with ease
                </Typography>
              </Grid>

              <Grid container item xs={12} justifyContent="center" spacing={6}>
                <KnaufInfoSection />
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <SearchIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Search
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <HomePageSearchBar
                    InputProps={{
                      classes: {
                        root: classes.searchBarInput,
                        notchedOutline: classes.searchBarOutline,
                      },
                    }}
                    placeholder="Search"
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <DashboardIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Quick Access
                  </Typography>
                </Grid>
                <QuickAccessCards />
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <HomeIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Top Visitors
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grow in timeout={1000}>
                    <HomePageTopVisited />
                  </Grow>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grow in timeout={1000}>
                    <HomePageRecentlyVisited />
                  </Grow>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <AnnouncementIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Announcements
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Grow in timeout={1000}>
                    <AnnouncementsCard max={10} />
                  </Grow>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grow in timeout={1000}>
                    <DevFriendsDayCard />
                  </Grow>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <StarIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Starred Entities
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grow in timeout={1000}>
                    <HomePageStarredEntities />
                  </Grow>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <HomeIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Team Ownership
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <UserOwnedResources />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <BuildIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Toolkit
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grow in timeout={1000}>
                    <UsefulLinksCard />
                  </Grow>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grow in timeout={1000}>
                    <ToolkitCard />
                  </Grow>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>
                    <EmojiEmotionsIcon style={{ verticalAlign: 'middle', marginRight: '8px' }} />
                    Fun Corner
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grow in timeout={1000}>
                    <XkcdComicCard showExplain showNav />
                  </Grow>
                </Grid>
                <Grid item>
                  <Grow in timeout={1000}>
                    <HomePageRandomJoke />
                  </Grow>
                </Grid>
                <Grid item>
                  <Grow in timeout={1000}>
                    <ShouldIDeployCard title='Should I deploy today?' timeZone="Europe/Berlin" />
                  </Grow>
                </Grid>
              </Grid>
            </Grid>
            <SupportCard />
            <ChangelogPopup />
          </Content>
        </Page>
      </VisitListener>
    </SearchContextProvider>
  );
};