import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade, Typography, Button, makeStyles, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '700px',
    width: '90%', // Ensure the modal fits within the screen width
    maxHeight: '90vh', // Ensure the modal fits within the screen height
    overflowY: 'auto', // Enable scrolling for overflow content
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
  leftAlign: {
    textAlign: 'left',
  },
  title: { // New style for additional spacing below title
    marginBottom: theme.spacing(2), // Adjust the value for desired spacing
  },
  changelogLink: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    fontSize: '0.875rem', // Smaller font size for the link
  },
}));

const CHANGELOG_VERSION = '1.24.0'; // Update this version when there are new changes

const ChangelogPopup = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const seenVersion = localStorage.getItem('changelogVersion');
    if (seenVersion !== CHANGELOG_VERSION) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('changelogVersion', CHANGELOG_VERSION);
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="changelog-modal-title"
      aria-describedby="changelog-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <Typography variant="h4" id="changelog-modal-title" className={classes.title}>
            What's New in CST-IDP
          </Typography>
          <Typography variant="body1" id="changelog-modal-description" paragraph className={classes.leftAlign}>
            Welcome to the latest version <strong>1.24</strong> of our Internal Developer Platform! Here are the new features and improvements:
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            <strong>## [1.24] - 2025-03-08:</strong>
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #1: Added a new plugin <strong>Container Vulnerability Scanner</strong>. This plugin allows users to view container vulnerabilities in Catalog from Microsoft Defender for Cloud directly within your IDP instance (Component). 
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            <em>Note: This feature is currently in <strong>BETA</strong> and undergoing testing. User feedback is welcomed as we continue to refine functionality.</em>
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            <strong>## [1.23] - 2025-03-04:</strong>
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #1: Added a new plugin <strong>Secret Sharing</strong> that allows users to securely share sensitive information like passwords, API keys, and other credentials within the organization (Security).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Improvement #1: Added basic Fact Retrievers for entities (Maturity).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            <strong>## [1.22] - 2025-02-27:</strong>
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #1: Added a new feature that allows users to provide feedback for entities (Component).
          </Typography>
          <Typography variant="body2" paragraph className={classes.leftAlign}>
            - Feature #2: Added Tech-Insight maturity level for each entity (Component).
          </Typography>
  
          <Typography className={classes.changelogLink}>
            <Link href="https://github.com/Knauf-Group/cst-knauf-idp/blob/main/CHANGELOG.md" target="_blank" rel="noopener">
            - View Full Changelog
            </Link>
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose} className={classes.button}>
            Got it!
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default ChangelogPopup;