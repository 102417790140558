import React from 'react';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import { Content, Page, Header } from '@backstage/core-components';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardHeader } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },
  cardLink: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  infoCard: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  gridContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  gridItem: {
    maxWidth: 445, // Constraint maximum width of cards
    width: '100%',
  },
  cardHeader: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary, // Use theme colors for consistency
  },
  cardHeaderTitle: {
    fontSize: '20px', // Custom font size for CardHeader title
  },
  cardContent: {
    flexGrow: 1,
  },
  cardIcon: {
    marginLeft: 'auto',
    color: theme.palette.primary.main,
  },
  customTypography: {
    fontSize: '0.875rem', // Custom font size for Typography
  },
}));

const LearningPathCard = ({ title, link, children }: { title: string; link: string; children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12} lg={4} className={classes.gridItem}>
      <div className={classes.cardContainer}>
        <Link to={link} className={classes.cardLink}>
          <Card className={classes.infoCard}>
            <CardActionArea>
              <CardHeader
                title={title}
                className={classes.cardHeader}
                classes={{ title: classes.cardHeaderTitle }} // Apply custom title style
                action={<ArrowForwardIcon className={classes.cardIcon} />}
              />
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </CardActionArea>
          </Card>
        </Link>
      </div>
    </Grid>
  );
};

export const LearningPaths = () => {
  const classes = useStyles();

  return (
    <Page themeId="home">
      <Header title="Learning Paths" subtitle="Your journey to mastering new skills" />
      <Content>
        <Box maxWidth="lg" mx="auto" px={0}> {/* Use Box to manage maxWidth and horizontal margins */}
          <Grid container spacing={4} justifyContent="flex-start" alignItems="flex-start" className={classes.gridContainer}>
            <LearningPathCard
              title="How to onboard existing service to IDP"
              link="/docs/default/component/cst-knauf-idp/onboarding/onboarding-existing-service"
            >
              <Typography variant="body1" paragraph className={classes.customTypography}>
                Ready to bring your <strong>existing service</strong> into the Knauf IDP ecosystem?
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                Follow our streamlined onboarding process to ensure seamless integration and management. Our step-by-step guide will help you align your service with our standards, ensuring optimal performance and compliance.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                To get started, simply click on this card to begin the onboarding process. If you encounter any issues or need further assistance, please do not hesitate to reach out to our dedicated IDP team for support.
              </Typography>
            </LearningPathCard>

            <LearningPathCard
              title="How to enable Kubernetes for your service"
              link="/docs/default/component/cst-knauf-idp/onboarding/onboarding-kubernetes-clusters"
            >
              <Typography variant="body1" paragraph className={classes.customTypography}>
                Ready to leverage the power of <strong>Kubernetes</strong> for your service?
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                To get started, simply click on this card to enable the <strong>Kubernetes</strong> for your service.
                Here you can find step by step guide how to integrate IDP with <strong>Kubernetes cluster(s)</strong> and how to enable <strong>Kubernetes</strong> for your service.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                If you encounter any issues or need further assistance, please do not hesitate to reach out to our dedicated IDP team for support.
              </Typography>
            </LearningPathCard>

            <LearningPathCard
              title="How to enable Container Registry for your service"
              link="/docs/default/component/cst-knauf-idp/onboarding/onboarding-container-registry"
            >
              <Typography variant="body1" paragraph className={classes.customTypography}>
                Follow our step-by-step guide to enable the <strong>Container Registry</strong> for your service. For example, in case of rollback this will help you to see the previous versions of your service container images stored in <strong>Container Registry</strong>.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                To get started, simply click on this card to enable the <strong>Container Registry</strong> for your service.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                If you encounter any issues or need further assistance, please do not hesitate to reach out to our dedicated IDP team for support.
              </Typography>
            </LearningPathCard>

            <LearningPathCard
              title="How to enable ArgoCD for your service"
              link="/docs/default/component/cst-knauf-idp/onboarding/onboarding-argocd"
            >
              <Typography variant="body1" paragraph className={classes.customTypography}>
                In IDP we can see the status of the service using Kubernetes tab in the service page. But now you can also see the status of the service in <strong>ArgoCD</strong>. This will help you to see the status of the service in a more detailed way.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                To get started, simply click on this card to enable the <strong>ArgoCD</strong> for your service.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                If you encounter any issues or need further assistance, please do not hesitate to reach out to our dedicated IDP team for support.
              </Typography>
            </LearningPathCard>
            
            <LearningPathCard
              title="How to enable Container Vulnerability Scanner for your service"
              link="/docs/default/component/cst-knauf-idp/onboarding/onboarding-container-vulnerability-scanner"
            >
              <Typography variant="body1" paragraph className={classes.customTypography}>
                Enhance your service's security posture by integrating the Container Vulnerability Scanner with your Service. This tool provides real-time visibility into security vulnerabilities within your container images directly in your IDP component overview.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                The scanner integrates with Microsoft Defender for Cloud to detect CVEs, misconfigurations, and other security issues in your containers. Learn how to configure your catalog file with the proper annotations and start monitoring your container security with just a few simple steps.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                By following this guide, you'll be able to view vulnerability severity levels, get remediation advice, and ensure your containerized applications maintain a strong security posture throughout their lifecycle.
              </Typography>
            </LearningPathCard>
            
            <LearningPathCard
              title=" What is Internal Developer Portal"
              link="/docs/default/component/cst-knauf-idp/onboarding/onboarding-what-is-idp"
            >
              <Typography variant="body1" paragraph className={classes.customTypography}>
                Discover the Internal Developer Portal (IDP) and learn how it can streamline your development processes. IDP integrates various tools and services, providing a unified experience for managing your tech ecosystem.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                This guide will introduce you to Backstage, the open-source framework behind IDP, and explain its architecture, core functionalities, and benefits for different roles within your organization.
              </Typography>
              <Typography variant="body1" paragraph className={classes.customTypography}>
                If you encounter any issues or need further assistance, please do not hesitate to reach out to our dedicated IDP team for support.
              </Typography>
            </LearningPathCard>
          </Grid>
        </Box>
      </Content>
    </Page>
  );
};