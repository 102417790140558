import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
  fetchApiRef,
  createComponentExtension,
} from '@backstage/core-plugin-api';
import { containerVulnerabilityApiRef, ContainerVulnerabilityClient } from '../api/ContainerVulnerabilityClient';

export const containerVulnerabilityPlugin = createPlugin({
  id: 'container-vulnerability-scanner',
  apis: [
    createApiFactory({
      api: containerVulnerabilityApiRef,
      deps: { discoveryApi: discoveryApiRef, fetchApi: fetchApiRef },
      factory: ({ discoveryApi, fetchApi }) =>
        new ContainerVulnerabilityClient(discoveryApi, fetchApi),
    }),
  ],
});

// Original card that shows both summary and detailed views depending on vulnerability type
export const ContainerVulnerabilityCard = containerVulnerabilityPlugin.provide(
  createComponentExtension({
    name: 'ContainerVulnerabilityCard',
    component: {
      lazy: () => import('./components/ImageSecurityOverview').then(m => m.ImageSecurityOverview),
    },
  }),
);

// Card that only shows summary cards for all vulnerabilities
export const ContainerVulnerabilitySummaryCard = containerVulnerabilityPlugin.provide(
  createComponentExtension({
    name: 'ContainerVulnerabilitySummaryCard',
    component: {
      lazy: () => import('./components/ImageSecurityOverview').then(m => m.ImageSecuritySummaryOverview),
    },
  }),
);