import { createApiRef, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ClusterInfo, Vulnerability, ContainerVulnerabilityApi } from '../src/types';

export const containerVulnerabilityApiRef = createApiRef<ContainerVulnerabilityApi>({
  id: 'plugin.container-vulnerability-scanner.service',
});

export class ContainerVulnerabilityClient implements ContainerVulnerabilityApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi
  ) {}

  async getClusters(): Promise<ClusterInfo[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('container-vulnerability-scanner');
    const response = await this.fetchApi.fetch(`${baseUrl}/clusters`);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch clusters: ${response.statusText}`);
    }
    
    const data = await response.json();
    return data.clusters;
  }

  async getVulnerabilities(clusterId: string, imageId: string): Promise<Vulnerability[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('container-vulnerability-scanner');
    const encodedImageId = encodeURIComponent(imageId);
    const url = `${baseUrl}/vulnerabilities/${clusterId}/${encodedImageId}`;
    
    const response = await this.fetchApi.fetch(url);
    
    if (!response.ok) {
      const data = await response.json().catch(() => ({}));
      const errorMsg = data.error || response.statusText;
      
      const error = new Error(errorMsg);
      if (response.status === 404 && data.errorType === 'CONTAINER_NOT_FOUND') {
        // Add special property to identify container not found errors
        error.name = 'ContainerNotFoundError';
      }
      throw error;
    }
    
    const data = await response.json();
    return data.vulnerabilities;
  }
}