import React from 'react';
import { Grid, Card as MuiCard, CardActionArea, CardContent, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  card: {
    height: '160px', // Slightly decreased height
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    boxShadow: theme.shadows[4],
    transition: 'transform 0.3s, box-shadow 0.3s',
    borderRadius: '1px', // Added border radius for rounded corners
  },
  // Using theme colors for better matching
  primary: {
    background: theme.palette.primary.main,
  },
  secondary: {
    background: theme.palette.secondary.main,
  },
  error: {
    background: theme.palette.error.main,
  },
  info: {
    background: theme.palette.info.main,
  },
  warning: {
    background: theme.palette.warning.main,
  },
  success: {
    background: theme.palette.success.main,
  },
  custom: {
    background: 'linear-gradient(135deg, #6a11cb, #2575fc)', // Purple gradient color
  },
  toolbox: {
    background: 'linear-gradient(135deg, #434343, #000000)', // Metallic gradient color
  },
  learningPaths: {
    background: 'linear-gradient(135deg, #DAA520, #FF8C00)', // Darker yellow gradient color
  },
  secretSharing: {
    background: 'linear-gradient(135deg, #2a8c44, #196f2e)', // Medium-dark green gradient color
  },
  content: {
    textAlign: 'center',
  },
}));

// Define the type for the card object
type QuickAccessCard = {
  color: CardColor;
  title: string;
  subtitle: string;
  href: string;
};

// Define the type for the color property
type CardColor = 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success' | 'custom' | 'toolbox' | 'learningPaths' | 'secretSharing';

const cards: QuickAccessCard[] = [
  {
    color: 'primary',
    title: 'Create a New Component',
    subtitle: 'Start building a new component',
    href: '/create',
  },
  {
    color: 'secondary',
    title: 'Software Catalog',
    subtitle: 'Browse the software catalog',
    href: '/catalog',
  },
  {
    color: 'error',
    title: 'Documentation',
    subtitle: 'Access detailed documentation',
    href: '/docs',
  },
  {
    color: 'learningPaths',
    title: 'Learning Paths',
    subtitle: 'Your journey to mastering new skills',
    href: '/learning-paths',
  },
  {
    color: 'secretSharing',
    title: 'Secret Sharing',
    subtitle: 'Securely share sensitive information',
    href: '/secret-sharing',
  },
  {
    color: 'toolbox',
    title: 'Toolbox',
    subtitle: 'Utilities for encoding, data generation, and conversion',
    href: '/toolbox',
  }
];

const QuickAccessCards = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} justifyContent="center">
      {cards.map(card => (
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={card.title}>
          <MuiCard className={`${classes.card} ${classes[card.color]}`}>
            <CardActionArea href={card.href}>
              <CardContent className={classes.content}>
                <Typography variant="h5">{card.title}</Typography> {/* Adjusted font size */}
                <Typography variant="body2">{card.subtitle}</Typography>
              </CardContent>
            </CardActionArea>
          </MuiCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default QuickAccessCards;