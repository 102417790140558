import {
    createUnifiedTheme,
    createBaseThemeOptions,
    palettes,
  } from '@backstage/theme';
  
  export const KnaufDarkTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.dark,
        // Custom dark theme colors
        background: {
          default: '#121212',
          paper: '#1e1e1e',
        },
        primary: {
          main: '#0077c8',
          contrastText: '#fff',
        },
        navigation: {
          background: 'linear-gradient(135deg, #000000, #1a1a1a)',
          indicator: '#0077c8',
          color: '#b5b5b5',
          selectedColor: '#fff',
          submenu: {
            background: 'linear-gradient(135deg, #000000, #1a1a1a)',
          },
        },
      },
    }),
    components: {
      BackstageSidebar: {
        styleOverrides: {
          drawer: ({ theme }) => ({
            background: 'linear-gradient(135deg, #000000, #1a1a1a)',
            width: '100%',
            display: 'flex',
            color: '#ffffff',
            boxShadow: theme.shadows[4],
            transition: 'transform 0.3s, box-shadow 0.3s',
            borderRadius: '1px',
            submenu: {
              background: 'linear-gradient(135deg, #000000, #1a1a1a)',
            }
          }),
        },
      },
      BackstageSidebarSubmenu: {
        styleOverrides: {
          title: {
            color: '#fff',
          },
        },
      },
      BackstageSidebarItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              borderLeft: '4px solid',
              borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
            },
            '&.Mui-selected': {
              borderLeft: '4px solid',
              borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
            },
          },
        },
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            background: 'linear-gradient(135deg, #000000, #1a1a1a)',
            color: '#fff',
          },
        },
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            '&:hover': {
              borderTop: '4px solid',
              borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
            },
            '&.Mui-selected': {
              borderTop: '4px solid',
              borderImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) 1',
            },
          },
        },
      },
      BackstageHeader: {
        styleOverrides: {
          header: ({ theme }) => ({
            padding: theme.spacing(3),
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
            backgroundImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%)',
            color: theme.palette.primary.contrastText,
            borderRadius: '1px',
          }),
        },
      },
      BackstageLogViewer: {
        styleOverrides: {
          log: {
            backgroundColor: '#1a1a1a',
            color: '#e0e0e0',
          },
          header: {
            backgroundColor: '#242424',
          }
        },
      },
      BackstageItemCardHeader: {
        styleOverrides: {
          root: {
            backgroundImage: 'linear-gradient(135deg, #005fa3 0%, #0077c8 100%) !important',
            boxShadow: 'unset',
            color: '#fff',
          },
        },
      },
    },
  });